<template>
  <div id="app" :key="fullWidth ? 1 : 0">
    <div v-if="error">{{error}}</div>
    <template v-else>
      <div v-if="!loaded">
        <span class="loader"></span>
      </div>
      <pdf v-for="i in numPages" :key="i" :src="src" :page="i" @progress="progress = $event" @loaded="loaded = true" :class="fullWidth ? null : 'full-height'"></pdf>
      <div class="minmax" v-if="false && loaded" @click="fullWidth = !fullWidth">
        <img v-if="fullWidth" src="data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTIxLjcxLDIwLjI5LDE4LDE2LjYxQTksOSwwLDEsMCwxNi42MSwxOGwzLjY4LDMuNjhhMSwxLDAsMCwwLDEuNDIsMEExLDEsMCwwLDAsMjEuNzEsMjAuMjlaTTExLDE4YTcsNywwLDEsMSw3LTdBNyw3LDAsMCwxLDExLDE4Wm00LThIN2ExLDEsMCwwLDAsMCwyaDhhMSwxLDAsMCwwLDAtMloiLz48L3N2Zz4=" />
        <img v-else src="data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE1LDEwSDEyVjdhMSwxLDAsMCwwLTIsMHYzSDdhMSwxLDAsMCwwLDAsMmgzdjNhMSwxLDAsMCwwLDIsMFYxMmgzYTEsMSwwLDAsMCwwLTJabTYuNzEsMTAuMjlMMTgsMTYuNjFBOSw5LDAsMSwwLDE2LjYxLDE4bDMuNjgsMy42OGExLDEsMCwwLDAsMS40MiwwQTEsMSwwLDAsMCwyMS43MSwyMC4yOVpNMTEsMThhNyw3LDAsMSwxLDctN0E3LDcsMCwwLDEsMTEsMThaIi8+PC9zdmc+" />
      </div>
    </template>
  </div>
</template>
<style>
.loader {
  position: absolute;
  left: calc(50% - 64px);
  top: calc(50% - 64px);
  width: 128px;
  height: 128px;
  border: 10px solid #edad4e;
  border-bottom-color: #eedcc1;;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation .8s linear infinite;
}
.full-height {
  width: 100vh;
  margin: auto;
}
.minmax {
  position: fixed;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.minmax img {
  width: 32px;
  height: 32px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>
<script>
import pdf from 'vue-pdf'

export default {
  name: 'App',
  components: {
    pdf
  },
  errorCaptured() {
    return false
  },
	data() {
		return {
			src: undefined,
			numPages: undefined,
      error: undefined,
      progress: 0,
      loaded: false,
      fullWidth: false
		}
	},
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url = urlParams.get('url');
    if (!url)
      this.error = 'Bad parameters';

    // Requestor side:
    // `https://pdf.sharespot.cz/?url=${EncodeURIComponent(btoa(url))}`
    this.src = pdf.createLoadingTask(atob(url));
    this.src.promise.then((pdf) => { 
      this.numPages = pdf.numPages; 
    });
  }
}
</script>
